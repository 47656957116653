<template>
  <div>
    <form-modal
      v-model="importDialog"
      title="Importar NF-e faltante"
      :cols="importCols"
      :opts="opts"
      :value.sync="selectedItem"
      @cancel="cancelImport()"
      @save="saveFromModal"
    ></form-modal>
    <modal-sequencia
      v-model="sequenceDialog"
      title="Validar Sequência"
      :cols="sequenceCols"
      :value.sync="selectedSequence"
      @save="sequenceValidate($event)"
    >      
    </modal-sequencia>
    <master-detail
      resourceId="nf_numero"
      :cols="cols"
      :customResource="customResource"
      :disableActContextOnClick="true"
      :disableTableSaveButton="disableTableSaveButton"
      :hasMonthlyFilter="true"
      :hasExportCSV="false"
      :opts="opts"
      :rows="rows"
      :serverPagination="true"
      @rowstoprint="rowsToPrint"
      @save="saveFromTable"
    >
      <div class="d-flex flex-row">
        <v-btn text @click="sequenceDialog = true" height="26px">          
          <v-icon class="mr-1">mdi-note-check-outline </v-icon>
          Validar Sequência
        </v-btn>        
        <async-export-button type="sequencia" :competencia="true"></async-export-button>
      </div>
    </master-detail>
  </div>
</template>

<script>
import { cloneDeep, sortBy, isEqual } from 'lodash';
import { mapGetters } from 'vuex';

export default {
  components: {
    "async-export-button": () => import("@/components/async-export-button.vue"),
    "form-modal": () => import("@/components/form-modal.vue"),
    "modal-sequencia": () => import("@/components/form-modal.vue"),
    "master-detail": () => import("@/components/master-detail.vue"),
  },
  computed: {
    ...mapGetters(['clientId', 'intervaloCompetencia']),
    client: function () {
      return this.getClient();
    },
    cols: function () {
      return [
        {
          key: "id",
          name: "ID",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "num_doc",
          name: "Nro Documento",
          type: this.$fieldTypes.TEXT,
          filterable: true,
        },
        {
          key: "serie",
          name: "Série",
          type: this.$fieldTypes.TEXT,
          filterable: true,
        },
        {
          key: "status",
          name: "Status",
          type: this.$fieldTypes.TEXT,
          filterable: true,
        },
        {
          key: "situacao_nf",
          name: "Situação NF",
          editOnTable: true,
          sortable: false,
          filterable: true,
          type: this.$fieldTypes.SELECT,
          width: 300,
          rel: { to: "situacao_nf", key: "name", name: "name" },
        },
      ];
    },
    sequenceCols: function() {
      return [
        {
          key: "mesCompetencia",
          name: "Competência",
          type: this.$fieldTypes.MONTH,
          rules: [{ rule: 'required' }],
          colSize: 6,
        },
      ]
    },
    importCols: function () {
      return [
        {
          key: "competencia",
          name: "Competência",
          type: this.$fieldTypes.MONTH,
          rules: [{ rule: 'required' }],
          colSize: 6,
        },
        {
          key: "arquivo",
          name: "Arquivo(s)",
          type: this.$fieldTypes.FILE,
          multiple: false,
        }
      ];
    },
    customResource: function () {
      const resource = this.apiResource(`/v1/faturamento/sequencia/${this.clientId}`);
      const pageRef = this;
      return {
        get(...args) {
          return resource.get(...args).then((result) => {
            if (!result.error) {
              const sorted = sortBy(result, ['serie', 'nf_numero']);
              pageRef.nfes = cloneDeep(sorted);
              pageRef.rows = cloneDeep(sorted);
            } else {
              pageRef.nfes = [];
              pageRef.rows = [];
            }

            return pageRef.rows;
          });
        },
        save(data, dataId) {
          return resource.save(data, dataId || data.id).then((response) => {
            const found = pageRef.nfes.find(({ id }) => data.id === id);

            if (!response.error && found) {
              found.situacao_nf = response.situacao_nf;
            }
            
            return response;
          });
        },
      };
    },    
    sequenceResource: function() {
      return this.apiResource(`/v1/faturamento/validasequencia/${this.clientId}`)      
    },
    opts: function () {
      return  {
        situacao_nf: [
          { name: "AUTORIZADA" },
          { name: "CANCELADA" },
          { name: "DENEGADA" },
          { name: "INUTILIZADA" },
        ],
      };
    },    
  },
  data: function () {
    return {
      competencia: [],
      nfes: [],
      rows: [],
      series: [],
      serie: null,
      loading: false,
      importDialog: false,
      sequenceDialog: false,
      selectedItem: null,
      selectedSequence: [],
    };
  },
  methods: {
    saveFromTable: function (row) {
      const item = cloneDeep(row);
      const { status, situacao_nf } = item;
      if (status === 'Faltante' && situacao_nf === 'AUTORIZADA') {
        this.selectedItem = item;
        this.importDialog = true;
      } else {
        this.customResource.save(item, item.id);
      }
    },
    saveFromModal: function (data, closeModal) {
      const item = cloneDeep(data);
      if (item.arquivo?.length > 0) {
        this.customResource.save(item, item.id).then(() => closeModal());
      }
    },
    disableTableSaveButton: function (row) {
      // eslint-disable-next-line no-unused-vars
      const { situacao_nfname, ...nfe } = { ...row };
      const old = this.nfes.find(({ id }) => nfe.id === id) || {};
      return isEqual(nfe, old);
    },
    rowsToPrint: function (resolve) {
      resolve(this.nfes);
    },
    cancelImport: function () {
      const index = this.nfes.findIndex(({ id }) => this.selectedItem.id === id);
      if (index >= 0) {
        this.rows.splice(index, 1, cloneDeep(this.nfes[index]));
      }
    },    
    sequenceValidate: function(data) {           
      if(this.clientId > 0) {
        this.sequenceResource.save({
          competenciaIni: data.mesCompetencia,
          competenciaFim: data.mesCompetencia
        }).then((result) => {
          this.$notify({
            group: "geral",
            duration: 5000,
            type: "success",
            title: "Validação iniciada.",
            text: `${result.validaSequencia}`,
          })
        })
      } else {
          this.$notify({
            group: "geral",
            duration: 5000,
            type: "error",
            title: "Selecione uma empresa",
            text: `Selecione uma empresa antes de continuar`,
          })
      }
      
      this.sequenceDialog = false;
    }
  }
};
</script>